
// Context.js
import React, { useEffect, useState } from "react";

export const Context = React.createContext();
export const UserContext = ({ children }) => {
    const [isLoggedin, setIsLoggedin] = useState(false);
    let [authUser, setAuthUser] = useState({});

    const signOutUser = () => {
        localStorage.removeItem('_user')
        localStorage.removeItem('user_access_token')
        setIsLoggedin(false);
    }
    const signInUser = (user_data) => {
        try {
            if (!user_data.access_token) {
                throw new TypeError("Access token not found");
            } else if (!user_data.user_id) {
                throw new TypeError("User ID not found");
            } else if (!user_data.email_address) {
                throw new TypeError("Email address not found");
            }
            let user = {
                user_id: user_data.user_id,
                email_address: user_data.email_address,
                first_name: user_data?.first_name ? user_data.first_name : '',
                surname: user_data?.surname ? user_data.surname : '',
                full_name: user_data?.full_name ? user_data.full_name : '',
                display_name: user_data?.display_name ? user_data.display_name : user_data.first_name,
                avatar: user_data?.avatar ? user_data.avatar : ''
            }
            localStorage.setItem('_user', JSON.stringify(user))
            setAccessToken(user_data.access_token)
            setIsLoggedin(true);
            setAuthUser(user)
            return true;
        } catch (err) {
            return { error: err };
        }
    }
    const setAccessToken = function (token) {
        return localStorage.setItem('user_access_token', token) ? true : undefined;
    }
    const getAccessToken = function () {
        let enc_token = localStorage.getItem('user_access_token');
        return enc_token;
    }
    const getAuthUser = () => {
        let user = localStorage.getItem('_user');
        if (user) {
            setAuthUser(JSON.parse(user));
        }
    }
    useEffect(() => {
        const access_token = getAccessToken();
        if (access_token) {
            setIsLoggedin(true);
            getAuthUser()
        }
    }, [])
    return (
        <Context.Provider value={{ authUser, signOutUser, signInUser, isLoggedin }}>
            {children}
        </Context.Provider>
    );
};