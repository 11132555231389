import React from 'react';
import { Col, Form, FormControl, FormGroup, FormLabel, FormSelect, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SEO from '../../../../../../components/SEO';

export const StepOne = ({ onSubmit }) => {
    const categories = [
        'Fintech', 'Agro', 'E-commerce', 'Fashion', 'Delivery', 'Crypto', 'Peer2peer', 'Non profit', 'sajjsa', 'sajij'
    ];
    return (
        <>
            <SEO title='User - Create an idea' />
            <FormGroup className='form-group mb-4'>
                <FormLabel className='mb-4'>
                    <h6 className='font-size-h5'>Gives this Idea a name<span className='text-danger'>*</span></h6>
                    <span className='text-soft font-size-sm'>This is open to change later</span>
                    <FormControl name='title' size='lg shadow-none' />
                </FormLabel>
            </FormGroup>

            <FormGroup className='form-group mb-4'>
                <h6 className='font-size-h5'>How would you categorize this Idea?</h6>
                <span className='text-soft font-size-sm'>Select a category this idea is associated with</span>
                <Row className='mb-3 no-gutters'>
                    {categories.slice(0, 9).map((c, i) => {
                        return <Col xs='6' md='4' key={i} className='p-2'>
                            {i === 8 ? <Link to='#' role='button' className='text-nowrap font-size-xs'>View all category..</Link> : <div className='d-flex align-items-center'>
                                <Form.Group controlId={"form-control-" + c.toLowerCase()}>
                                    <Form.Check type="checkbox" name='category[]' className='mr-2' label={c} />
                                </Form.Group>
                            </div>}
                        </Col>
                    })}
                </Row>
            </FormGroup>
            <FormGroup className='form-group mb-4'>
                <FormLabel>
                    <h6 className='font-size-h5'>Who will benefit from this solution?</h6>
                    <span className='text-soft font-size-sm'>This can be category of people who currently experience this problem</span>
                    <FormSelect className='custom-select'>
                        <option>University Students</option>
                    </FormSelect>
                </FormLabel>
            </FormGroup>
        </>
    )
}