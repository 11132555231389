import { toast } from "react-toastify";

export const useNotify = () => {
    const notify = (msg) => {
        toast(msg, {
            position: "top-center",
            autoClose: false,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            className: "bg-primary text-light text-center mt-5 min-h-50px shadow",
            bodyClassName: "p-2",
        });
    };
    return { notify }
}
