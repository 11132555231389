import React from 'react';
import { FormControl, FormGroup, FormLabel } from 'react-bootstrap';

export const StepThree = ({ onSubmit }) => {
    return (
        <>
            <FormGroup className='form-group mb-4'>
                <FormLabel className='mb-4'>
                    <h6 className='font-size-h5'>Describe the problem or opportunity you have identified</h6>
                    <span className='text-soft font-size-sm'>what is the pain points or gap identified</span>
                    <FormControl as='textarea' name='name' rows={3} size='lg' className='shadow-none' />
                </FormLabel>
            </FormGroup>
            <FormGroup className='form-group mb-4'>
                <FormLabel className='mb-4'>
                    <h6 className='font-size-h5'>Your potential solution to this problem</h6>
                    <span className='text-soft font-size-sm'>Its okay if you do not have a potential solution at the moment, You request contribution from others!</span>
                    <FormControl as='textarea' name='solution' rows={3} size='lg' className='shadow-none' />
                </FormLabel>
            </FormGroup>
            <FormGroup className='form-group mb-4'>
                <FormLabel className='mb-4'>
                    <h6 className='font-size-h5'>Upload document</h6>
                    <span className='text-soft font-size-sm'>This can be sketches that helps us to understand the idea, Only images can be uploaded</span>
                    <FormControl type='file' multiple name='files' accept='image/*,.pdf' size='lg' className='shadow-none' />
                </FormLabel>
            </FormGroup>
        </>
    )
}