import React, { useEffect, useState } from "react";
import {
	Card,
	Container
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { API, Utils } from "../../../components/Utilities.js";
import { Logo } from "../../../components/Logo";
import "../auth.css";

import { RecoveryComponent } from "./components/recover";
import { ResetComponent } from "./components/reset";

export const ResetPassword = () => {
	const navigate = useNavigate();
	const [actionStep, setActionStep] = useState(1);
	const [userEmail, setUserEmail] = useState({
		value: "",
	});
	useEffect(() => {
		setUserEmail(1);
	}, [setUserEmail]);
	const __reset = (email) => {
		if (email){
			return API.post("/auth/password/reset", {
				email_address: email,
			});
		}else{
			console.log(email);
			Utils.toast({
				title:'Reset password',
				type:'error',
				text:'Invalid email'
			})
		}

	};
	return (
		<>
			<Container className="auth-wrapper">
				<Card className="border-0 max-w-400px mx-auto text-center py-3 w-100">
					<div className="mb-3">
						<Logo />
					</div>
					{(() => {
						switch (actionStep) {
							case 1:
								return (
									<ResetComponent
										email_address={userEmail.value}
										reset={__reset}
										on_reset={(email, resp) => {
											console.log(
												"Returned reset password response",
												resp
											);
											if (
												resp.status &&
												resp.code === 200
											) {
												setUserEmail({
													...userEmail,
													value: email,
												});
												setActionStep(2);
											}
										}}
									/>
								);
							case 2:
								return (
									<RecoveryComponent
										email_address={userEmail.value}
										resend_token={__reset}
										on_recover={(resp) => {
											console.log(resp);
											if (
												resp.state &&
												resp.code === 200
											) {
												navigate("/user/");
											}
										}}
									/>
								);
							default:
								return null;
						}
					})()}
				</Card>
			</Container>
		</>
	);
};
