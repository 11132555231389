export const useValidateFormFields = () => {
    const validatePassword = password => {
        let value = password,
            is_valid = true,
            err_msg = '';
        if (
            value.length === 0 ||
            value.length < 8 ||
            value.search(/[A-Z]/i) < 0 ||
            value.search(/[a-z]/i) < 0 ||
            value.search(/[0-9]/) < 0
        ) {
            is_valid = false;
            if (value.length === 0) {
                err_msg = "Password cannot be empty";
            } else if (value.length < 8) {
                err_msg = "Password should be at least 8 character long";
            } else if (value.search(/[A-Z]/i) < 0) {
                err_msg = "Password should have at least one Uppercase character";
            } else if (value.search(/[a-z]/i) < 0) {
                err_msg = "Password should have at least one Lowercase character";
            } else if (value.search(/[0-9]/) < 0) {
                err_msg = "Your password must contain at least one digit.";
            }
        }
        return {
            valid: is_valid,
            value: value,
            message: err_msg
        }
    }
    const validateEmail = email => {
        let value = email.trim(),
            is_valid = true,
            err_msg = '';
        if (value.length === 0 || !value.match(/^\S+@\S+\.\S+$/)) {
            is_valid = false
            if (value.length === 0) {
                err_msg = "Email address is required";
            } else if (!value.match(/^\S+@\S+\.\S+$/)) {
                err_msg = "Invalid email address";
            }
        }
        return {
            valid: is_valid,
            value: value,
            message: err_msg
        }
    }
    return {
        validatePassword: validatePassword,
        validateEmail: validateEmail
    }
}
