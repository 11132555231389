import React, { useState } from "react";
import { Button, FormControl, FormLabel, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { API, Utils } from "../../../../../components/Utilities.js";
import { AuthCode, BlockLoader } from "../../../../../components/Widgets.js";

export const RecoveryComponent = ({ email_address, resend_token }) => {
	const [showPassword, setShowPassword] = useState(false);
	const [showNewPassword, setShowNewPassword] = useState(false);
	const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false);
	const [resendBtnWait, setResendBtnWait] = useState(false);
	const [passwordBtnWait, setPasswordBtnWait] = useState(false);
	const [verifyingToken, setVerifyingToken] = useState(false);
	const [resetParams, setResetParams] = useState({
		new_password: "",
		reset_token: "",
	});
	const [validPassState, setValidPassState] = useState({
		invalid: false,
		message: "",
	});

	const set_password = (e) => {
		e.preventDefault();

		setHasBeenSubmitted(true);
		let params = {
				email_address: email_address,
				...resetParams,
			},
			toast_opts = {
				title: "Account login",
				type: "error",
				text: "Failed to complete request",
			};
		console.log(params);
		if (!params.email_address || params.email_address.length === 0) {
			toast_opts.text = "Email address is missing";
		} else if (!params.reset_token || params.reset_token.length === 0) {
			toast_opts.text = "Reset token is missing";
		} else if (!params.new_password || params.new_password.length === 0) {
			toast_opts.text = "Provide your new password";
		} else {
			setPasswordBtnWait(true);
			return API.post("/auth/password/reset", params).then((resp) => {
				setPasswordBtnWait(false);
				console.log(resp);
				if (resp.status && resp.code === 200) {
				} else {
					toast_opts.text = resp.message
						? resp.message
						: "Failed to complete request";
					Utils.toast(toast_opts);
				}
			});
		}
		Utils.toast(toast_opts);
	};
	const verify_token = (token) => {
		let toast_opts = {
			title: "Token verification",
			type: "error",
			text: "Failed to process request",
		};
		console.log(email_address);
		if (email_address) {
			let params = {
				email_address: email_address,
				reset_token: resetParams.reset_token,
			};
			setVerifyingToken(true);
			console.log(params);
			return API.post("auth/password/reset", params).then((resp) => {
				setVerifyingToken(false);
				console.log(resp);
				if (resp.status && resp.code === 200) {
					setShowNewPassword(true);
				} else {
					toast_opts.text = resp.message
						? resp.message
						: toast_opts.text;
					Utils.toast(toast_opts);
				}
			});
		} else {
			toast_opts.text = "Invalid email address";
			Utils.toast(toast_opts);
		}
	};
	const validate_password = (e) => {
		let elm = e.target,
			value = elm.value,
			err_obj = {
				invalid: false,
				message: "",
			};

		value = value.trim();
		if (
			value.length === 0 ||
			value.length < 8 ||
			value.search(/[A-Z]/i) < 0 ||
			value.search(/[a-z]/i) < 0 ||
			value.search(/[0-9]/) < 0
		) {
			err_obj = {
				invalid: true,
			};
			if (value.length === 0) {
				err_obj.message = "Password cannot be empty";
			} else if (value.length < 8) {
				err_obj.message =
					"Password should be at least 8 character long";
			} else if (value.search(/[A-Z]/i) < 0) {
				err_obj.message =
					"Password should have at least one Uppercase character";
			} else if (value.search(/[a-z]/i) < 0) {
				err_obj.message =
					"Password should have at least one Lowercase character";
			} else if (value.search(/[0-9]/) < 0) {
				err_obj.message =
					"Your password must contain at least one digit.";
			}
			setValidPassState(err_obj);
		}
		setResetParams({
			...resetParams,
			new_password: value,
		});
	};
	return (
		<>
			<div className="mb-4">
				<h1 className="auth-title font-size-h3">Recover Account</h1>
				<span className="auth-subtitle">
					You’re almost there!,{" "}
					{!showNewPassword
						? "we sent an email to"
						: "provide new password"}
				</span>
			</div>
			<p className="text-dark font-weight-bold font-size-lg mb-0">
				{email_address}
			</p>
			<div className="position-relative py-3">
				<div>
					{verifyingToken && <BlockLoader />}
					<AuthCode
						name="reset_token"
						onComplete={(val, index) => {
							console.log("Show password");
							// Show New Password input
							setResetParams({
								...resetParams,
								reset_token: val,
							});
							verify_token(val);
						}}
					/>

					<p className="text-soft my-3">
						Just click on the link in that email to complete your
						sign up. If you don’t see it, you may need to{" "}
						<strong className="text-dark">check your spam</strong>{" "}
						folder
					</p>
					<div className="my-3">
						<p className="m-0">Still can’t find the email?</p>
						<Button
							type="button"
							variant="primary"
							className="my-2 btn-block mx-auto max-w-300px py-2 font-weight-bold font-size-lg"
							onClick={(e) => {
								e.preventDefault();
								console.log("Resend code");
								setResendBtnWait(true);
								resend_token(email_address).then((resp) => {
									setResendBtnWait(false);
									console.log(resp);
								});
							}}
							disabled={resendBtnWait}>
							{resendBtnWait ? (
								<>
									<Spinner
										size="sm mr-2"
										className="my-auto"
										animation="border"
										role="status">
										<span className="sr-only">
											Loading...
										</span>
									</Spinner>
									Procesing...
								</>
							) : (
								"Resend Email"
							)}
						</Button>
						<p>
							Need help?{" "}
							<Link
								to="#"
								className="link">
								Contact Us
							</Link>
						</p>
					</div>
				</div>
				<div
					className={
						"new-password" + (showNewPassword ? " shown" : "")
					}>
					<form
						method="POST"
						action="./"
						onSubmit={set_password}
						className="w-100 auth-form">
						<FormLabel className="mb-4">
							Password
							<div
								className={
									"password-input" +
									(showPassword ? " shown" : "")
								}>
								<FormControl
									type={showPassword ? "text" : "password"}
									name="new_password"
									placeholder="Enter your Password"
									value={resetParams.new_password}
									className="bg-transparent"
									isInvalid={
										hasBeenSubmitted &&
										validPassState.invalid
									}
									onChange={validate_password}
								/>
								{hasBeenSubmitted && validPassState.invalid && (
									<span className="input-val-msg position-absolute top-100">
										{validPassState.message}
									</span>
								)}
								<div className="position-absolute top-0 right-0 h-100 zindex-2 d-flex px-2">
									<Button
										type="button"
										variant="light"
										className="my-auto btn-icon password-toggle"
										onClick={(e) => {
											e.preventDefault();
											setShowPassword(!showPassword);
										}}></Button>
								</div>
							</div>
						</FormLabel>
						<Button
							type="submit"
							className="btn-primary btn-submit align-items-center d-flex"
							disabled={passwordBtnWait}>
							{passwordBtnWait ? (
								<>
									<Spinner
										size="sm mr-2"
										className="my-auto"
										animation="border"
										role="status">
										<span className="sr-only">
											Loading...
										</span>
									</Spinner>
									Loading...
								</>
							) : (
								"Set Password"
							)}
						</Button>
					</form>
				</div>
			</div>
		</>
	);
};
