import { Spinner } from "react-bootstrap";
import PinInput from 'react-pin-input';

export const Avatar = ({ img_src, name, size = 'md', className = '' }) => {
    const user = {
        img: typeof img_src === 'string' ? img_src : '',
        name: typeof name === 'string' ? name : ''
    }
    let initials_arr = user.name.split(' '),
        initials = initials_arr[0].slice(0, 1).toUpperCase() + (initials_arr.length > 1 ? initials_arr[1].slice(0, 1) : ''),
        avatar = user.img.length > 0 ? <img src={user.img} alt={user.name} /> : <span>{initials}</span>
    return (
        <div className={'img-thumbnail rounded-circle user-avatar d-flex align-items-center justify-content-center bg-light ' + size + " " + className}>
            {avatar}
        </div>
    )
}

export const UserCard = ({ img_src, name, email }) => {
    return (
        <div className='d-flex align-items-center user-card p-3'>
            <Avatar img_src={img_src} name={name} />
            <div className='user-info flex-column d-flex ml-2 line-height-md'>
                <span style={{ color: '#020202' }}>{name}</span>
                <span className='text-muted font-size-xs'>{email}</span>
            </div>
        </div>
    )
}

export const AuthCode = ({ size = 40, onComplete, onChange }) => {
    return (
        <PinInput
            length={6}
            type="numeric"
            inputMode="number"
            onChange={onChange}
            onComplete={onComplete}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%'
            }}
            inputStyle={{
                display: 'inline-block',
                padding: '0.4375rem 1rem',
                fontWeight: '400',
                fontSize: Number(size / 2).toFixed(0) + 'px',
                color: '#3c4d62',
                backgroundColor: "#fff",
                backgroundClip: 'padding-box',
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: '#dbdfea',
                borderRadius: '4px',
                transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out'
            }}
            inputFocusStyle={{
                color: '#3c4d62',
                backgroundColor: '#fff',
                borderColor: 'var(--primary)',
                outline: 0,

                boxShadow: 'none'
            }} />
    )
}

export const BlockLoader = ({ opacity = 0.8, overlay = 'white' }) => {
    return (<div className='position-absolute d-flex justify-content-center align-items-center outset'>
        <div style={{
            position: 'absolute',
            inset: 0,
            backgroundColor: overlay,
            opacity: opacity
        }}>

        </div>
        <Spinner />
    </div >)
}