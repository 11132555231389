import React from 'react'
import {Link} from 'react-router-dom';
import logo from '../assets/img/logo.png';
export const Logo = () => {
  return (
      <Link to='/' className="navbar-brand logo mb-1">
          <img src={logo} alt='Buzit' />
      </Link>
  )
}
