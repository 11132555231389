import { useGoogleLogin } from '@react-oauth/google';
import React, { useState } from 'react';
import { Button, Card, Container, FormControl, FormLabel, Spinner } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { API, Utils } from '../../../components/Utilities';
import { Logo } from '../../../components/Logo';
import '../auth.css';

export const SignUp = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false);
  const [validStates, setValidStates] = useState({
    full_name: {
      invalid: true,
      message: 'Provide full name'
    },
    email_address: {
      invalid: true,
      message: 'Email address is required'
    },
    password: {
      invalid: true,
      message: 'Password is required'
    }
  });
  const [formParams, setFormParams] = useState({
    full_name: '',
    email_address: '',
    password: ''
  });
  const register = e => {
    e.preventDefault();
    setHasBeenSubmitted(true);
    let toast_opts = {
      title: 'Account registration',
      type: 'error',
      text: 'Failed to complete request'
    }, err_obj = validStates;
    if (formParams.email_address.length === 0) {
      toast_opts.text = 'Full name is required';
      err_obj.full_name = {
        invalid: true,
        message: toast_opts.text
      }
    } else if (formParams.email_address.length === 0) {
      toast_opts.text = 'Email is required';
      err_obj.email_address = {
        invalid: true,
        message: toast_opts.text
      }
    } else if (formParams.password.length === 0) {
      toast_opts.text = 'Password is required';
      err_obj.password = {
        invalid: true,
        message: toast_opts.text
      }
    } else {
      setBtnLoading(true);
      API.post('/auth/register', formParams).then(resp => {
        setBtnLoading(false);
        console.log(resp);
        if (resp.status && resp.code === 200) {
          navigate('/user/');
        } else {
          toast_opts.text = resp.message ? resp.message : 'Failed to complete request'
          Utils.toast(toast_opts);
        }
      })
    }
  }
  const validate_input = e => {
    let elm = e.target,
      name = elm.getAttribute('name'),
      value = elm.value,
      reject_input = false,
      err_obj = {
        invalid: false,
        message: ''
      };
    if (name === 'full_name') {
      if (value.length === 0 || value.trim().split(' ').length <= 1) {
        err_obj = {
          invalid: true
        }
        if (value.length === 0) {
          err_obj.message = 'Full name is required';
        } else if (value.trim().split(' ').length <= 1) {
          err_obj.message = 'Atleast 2 names are expected';
        }
      }
    } else if (name === 'email_address') {
      value = value.trim();
      if (value.length === 0 || !value.match(/^\S+@\S+\.\S+$/)) {
        err_obj = {
          invalid: true
        }
        if (value.length === 0) {
          err_obj.message = 'Email address is required';
        } else if (!value.match(/^\S+@\S+\.\S+$/)) {
          err_obj.message = 'Invalid email address';
        }
      }
    } else if (name === 'password') {
      if (value.length === 0 || value.length < 8 || value.search(/[A-Z]/) < 0 || value.search(/[a-z]/) < 0 || value.search(/[0-9]/) < 0) {
        err_obj = {
          invalid: true
        }
        if (value.length === 0) {
          err_obj.message = 'Password cannot be empty';
        } else if (value.length < 8) {
          err_obj.message = "Password should be at least 8 character long";
        } else if (value.search(/[A-Z]/) < 0) {
          err_obj.message = "Password should have at least one Uppercase character";
        } else if (value.search(/[a-z]/) < 0) {
          err_obj.message = "Password should have at least one Lowercase character";
        } else if (value.search(/[0-9]/) < 0) {
          err_obj.message = "Your password must contain at least one digit.";
        }
      }
    }
    setValidStates({ ...validStates, [name]: err_obj });
    if (!reject_input) setFormParams({ ...formParams, [name]: value });
  }
  const googleSignup = useGoogleLogin({
    onSuccess: (gresp) => {
      API.get(
        `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${gresp.access_token}`,
        {
          headers: {
            Authorization: `Bearer ${gresp.access_token}`,
          },
        }).then((res) => {
          //  googleID, gmail, name, etc...
          console.log(res.data);
        });
    },
    onError: (error) => console.log("Login Failed:", error),
  });
  return (
    <>
      <Container className='auth-wrapper'>
        <Card className='border-0 max-w-400px mx-auto text-center py-3 w-100'>
          <div className='mb-3'>
            <Logo />
          </div>
          <div className='mb-4'>
            <h1 className='auth-title font-size-h2'>Hi, Let’s get to know you</h1>
            <span className='auth-subtitle'>Lets share Ideas together</span>
          </div>
          <Button variant='outline-light' size='lg' className='google-btn' onClick={e => {
            e.preventDefault();
            return googleSignup();
          }}>Continue with Google</Button>
          <div className='alt-auth'>
            <span className='alt-auth-text'>or Sign up with email</span>
          </div>
          <form method='POST' action='#' className='text-left auth-form' onSubmit={register}>
            <FormLabel className='mb-4'>
              Full Name
              <FormControl isInvalid={hasBeenSubmitted && validStates.full_name.invalid} type='text' name='full_name' placeholder='Enter your full name' value={formParams.full_name} onChange={validate_input} />
              {(hasBeenSubmitted && validStates.full_name.invalid) && <span className='input-val-msg'>{validStates.full_name.message}</span>}
            </FormLabel>
            <FormLabel className='mb-4'>
              Email Address
              <FormControl isInvalid={hasBeenSubmitted && validStates.email_address.invalid} type='email' name='email_address' placeholder='Enter your email address' value={formParams.email_address} onChange={validate_input} />
              {hasBeenSubmitted && validStates.email_address.invalid && <span className='input-val-msg'>{validStates.email_address.message}</span>}
            </FormLabel>
            <FormLabel className='mb-4 pb-2'>
              Password
              <div className={'password-input' + (showPassword ? ' shown' : '')}>
                <FormControl
                  isInvalid={hasBeenSubmitted && validStates.password.invalid}
                  isValid={hasBeenSubmitted && !validStates.password.invalid}
                  type={showPassword ? 'text' : 'password'}
                  name='password'
                  placeholder='Enter your Password'
                  value={formParams.password}
                  onChange={validate_input}
                  className='bg-transparent' />
                {hasBeenSubmitted && validStates.password.invalid && <span className='input-val-msg position-absolute top-100'>{validStates.password.message}</span>}
                <div className='position-absolute top-0 right-0 h-100 zindex-2 d-flex px-2'>
                  <Button type='button' variant='light' className='my-auto btn-icon password-toggle' onClick={e => {
                    e.preventDefault()
                    setShowPassword(!showPassword);
                  }}>
                  </Button>
                </div>
              </div>
            </FormLabel>
            <Button type='submit' className='btn-primary btn-submit' disabled={btnLoading}>
              {btnLoading ? <>
                <Spinner size='sm mr-2' className='my-auto' animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
                Processing...</> : 'Register'
              }
            </Button>
            <p className='text-center mb-0 mt-2'>I have an account? <Link to='/auth/login'>Sign In</Link></p>
          </form>
        </Card>
      </Container>
    </>
  )
}
