import React from 'react'
import SEO from '../../../../components/SEO'
import { Button, ListGroup, ListGroupItem } from 'react-bootstrap'
import { Avatar } from '../../../../components/Widgets'

export const Connections = () => {
  const connections = [
    {
      id: 'ay812',
      avatar: 'https://i.pravatar.cc/150?img=3',
      full_name: 'Laura Write',
      first_name: 'Laura',
      surname: 'Write',
      display_name: 'Laura'
    },
    {
      id: 'dk859',
      avatar: 'https://i.pravatar.cc/150?img=10',
      full_name: 'Palito Comola',
      first_name: 'Palito',
      surname: 'Comola',
      display_name: 'Pal'
    }
  ]
  return (
    <>
      <SEO title='User - Connections' />
      <div className='border rounded-lg px-3 mt-3'>
        <ListGroup className='list-group-flush'>
          {connections.map((c, i) => (
            <ListGroupItem key={c.id} className='d-flex align-items-center py-4'>
              <Avatar img_src={c.avatar} className='mr-3' />
              <div className='flex-grow-1'>
                <span className='font-size-h5 font-weight-bold'>{c.full_name}</span>
                <span className='text-soft font-size-sm font-weight-bold mb-3'>{c.email_address}</span>
              </div>
              <Button variant='outline-primary' size='sm' className='px-3 rounded-pill'>Message</Button>
            </ListGroupItem>
          ))}
        </ListGroup>
      </div>
    </>


  )
}
