import React from 'react'
import SEO from '../../../../components/SEO'
export const SavedIdeas = () => {
  return (
    <>
    <SEO title='Sparkuz - Share an idea' />
      <div>SavedIdeas</div>
    </>
  )
}
