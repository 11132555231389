import React, { useState } from 'react';
import { Button, Container, Form, Nav, NavItem, NavLink, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { API } from '../../../../components/Utilities';
import { StepFour, StepOne, StepThree, StepTwo } from './tabs/';

export const NewIdea = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [btnWait, setBtnWait] = useState(false);
  const form_steps = [
    {
      title: 'Add New Idea',
      desc: 'Lets start with some basic details about this Idea'
    },
    {
      title: 'Overview',
      desc: 'Tell us more about this Idea'
    },
    {
      title: 'Idea Details',
      desc: 'Tell us more about this Idea'
    },
    {
      title: 'Preview',
      desc: 'Kindly double check idea details before posting'
    }
  ];
  const submitForm = e => {
    e.preventDefault();
    let params = Object.fromEntries(new FormData(e.target).entries())
    switch (activeStep) {
      case 3:
        //Validate step four an submit
        console.log(params);
        setBtnWait(true);
        API.post('account/ideas/post', params).then(resp => {
          setBtnWait(false);
          console.log(resp);
        })
        break;
      case 2:
        //Validate step three
        break;
      case 1:
        //Validate step two
        break;
      default:
        //Validate step one

        break;
    }
    if (activeStep >= 0 && activeStep < 3) {
      setActiveStep(activeStep + 1)
    }
  }
  return (
    <>
      <Container className='max-w-600px my-4'>
        <Nav className='nav-justified nav-tabs font-size-sm nav-line nav-line-3x mb-3 align-items-stretch'>
          <NavItem className='nav-item'>
            <NavLink as={Link} className={activeStep >= 0 ? 'active' : ''}>Basic Info</NavLink>
          </NavItem>
          <NavItem className='nav-item'>
            <NavLink as={Link} className={activeStep >= 1 ? 'active' : ''}>Overview</NavLink>
          </NavItem>
          <NavItem className='nav-item'>
            <NavLink as={Link} className={activeStep >= 2 ? 'active' : ''}>Idea details</NavLink>
          </NavItem>
          <NavItem className='nav-item'>
            <NavLink as={Link} className={activeStep >= 3 ? 'active' : ''}>Preview</NavLink>
          </NavItem>
        </Nav>
      </Container>
      <Container className='d-flex justify-content-center align-items-start position-relative'>
        <div className='position-absolute top-0 left-0'>
          {activeStep > 0 && <Button as='a' variant='none' className='submit-btn shadow-none d-inline-flex align-items-center text-primary' onClick={e => {
            e.preventDefault();
            if (activeStep > 0) setActiveStep(activeStep - 1)
          }}>
            <i className='icon bx bx-chevron-left bx-sm'></i>
            <span className='text-underline'>Back</span>
          </Button>}
        </div>

        <div className='max-w-600px text-center w-100 py-3'>
          <h1 className='font-size-h3 font-weight-bold'>{form_steps[activeStep].title}</h1>
          <p className='font-size-h6' style={{
            color: '#0E0A14'
          }}>{form_steps[activeStep].desc}</p>
        </div>
        <span></span>
      </Container>
      <Container as={Form} onSubmit={submitForm} className='max-w-600px'>
        <div className='max-w-500px mx-auto'>
          {
            <div className='overflow-hidden' >
              <div style={{
                display: 'flex',
                alignItems: 'stretch',
                alignContent: 'stretch',
                width: '400%',
                transition: '.45s ease-in-out',
                marginLeft: (0 - (activeStep * 100)) + '%'
              }}>
                <div className='w-100' style={{
                  height: (activeStep !== 0 ? 0 : 'auto')
                }}>
                  <StepOne />
                </div>
                <div className={'w-100'} style={{
                  height: (activeStep !== 1 ? 0 : 'auto')
                }}><StepTwo /></div>
                <div className={'w-100'} style={{
                  height: (activeStep !== 2 ? 0 : 'auto')
                }}><StepThree /></div>
                <div className={'w-100'} style={{
                  height: (activeStep !== 3 ? 0 : 'auto')
                }}><StepFour /></div>
              </div>
            </div>
          }
          {/* {{
            0: <StepOne />,
            1: <StepTwo />,
            2: <StepThree />,
            3: <StepFour />
          }[activeStep]} */}
          <Button type='submit' className='btn-submit w-100 mt-3'>
            {btnWait ? (
              <>
                <Spinner
                  size="sm mr-2"
                  className="my-auto"
                  animation="border"
                  role="status">
                  <span className="sr-only">
                    Loading...
                  </span>
                </Spinner>
                Processing...
              </>
            ) : (activeStep === 3 ? 'Post' : 'Continue')}
          </Button>
        </div>
      </Container>
    </>

  )
}
