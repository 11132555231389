import React, { useState } from "react";
import {
	Button,
	FormControl,
	FormLabel,
	Spinner
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Utils } from "../../../../../components/Utilities";

export const ResetComponent = ({ reset, on_reset }) => {
	const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false);
	const [btnLoading, setBtnLoading] = useState(false);
	const [email, setEmail] = useState("");
	const [emailValidation, setEmailValidation] = useState({
		invalid: true,
	});
	const reset_password = (e) => {
		e.preventDefault();
		setHasBeenSubmitted(true);
		let toast_opts = {
			title: "Account login",
			type: "error",
			text: "Failed to complete request",
		};
		if (email.length > 0) {
			setBtnLoading(true);
			reset(email).then((resp) => {
				setBtnLoading(false);
				if (resp.status && resp.code === 200) {
					on_reset(email, resp);
				} else {
					toast_opts.text = resp.message
						? resp.message
						: "Failed to complete request";
					Utils.toast(toast_opts);
				}
			});
		} else {
			toast_opts.text = "Provide email address";
			Utils.toast(toast_opts);
		}
	};
	return (
		<div>
			<div className="mb-4">
				<h1 className="auth-title">Forgot password?, No worries.</h1>
				<span className="auth-subtitle">
					You’ll get an email to reset password
				</span>
			</div>
			<form
				method="POST"
				action="#"
				className="text-left auth-form"
				onSubmit={reset_password}>
				<FormLabel className="mb-4">
					Email Address
					<FormControl
						type="email"
						name="email_address"
						placeholder="Enter your email address"
						value={email}
						isInvalid={hasBeenSubmitted && emailValidation.invalid}
						required
						onChange={(e) => {
							let elm = e.target,
								value = elm.value.trim(),
								reject_input = false,
								err_obj = {
									invalid: false,
									message: "",
								};
							if (
								value.length === 0 ||
								!value.match(/^\S+@\S+\.\S+$/)
							) {
								err_obj = {
									invalid: true,
								};
								if (value.length === 0) {
									err_obj.message =
										"Email address is required";
								} else if (!value.match(/^\S+@\S+\.\S+$/)) {
									err_obj.message = "Invalid email address";
								}
							}
							setEmailValidation(err_obj);
							if (!reject_input) setEmail(value);
						}}
					/>
					{hasBeenSubmitted && emailValidation.invalid && (
						<span className="input-val-msg">
							{emailValidation.message}
						</span>
					)}
				</FormLabel>

				<Button
					type="submit"
					className="btn-primary btn-submit align-items-center d-flex"
					disabled={btnLoading}>
					{btnLoading ? (
						<>
							<Spinner
								size="sm mr-2"
								className="my-auto"
								animation="border"
								role="status">
								<span className="sr-only">Loading...</span>
							</Spinner>
							Processing...
						</>
					) : (
						"Send Recovery Email"
					)}
				</Button>
				<p className="text-center mb-0 mt-2">
					<Link to="/auth/login">I think I remember my password</Link>
				</p>
			</form>
		</div>
	);
};
