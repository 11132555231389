import React, { useContext, useEffect, useState } from "react";
import { Button, Container, Dropdown, FormControl } from "react-bootstrap";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { Context } from '../contexts/UserContext';
import { Logo } from '../components/Logo';
import SEO from "./SEO";
import { Avatar } from "./Widgets";


const SignedInHeader = ({ signOutUser, auth_user }) => {
    const [user, setUser] = useState({});
    useEffect(() => {
        setUser(auth_user)
    }, [auth_user])
    return (
        <>
            <div className='d-flex align-items-center ml-md-auto'>
                <div className='d-md-flex d-none'>
                    <Button variant='outline-primary' size='sm' as={Link} to="/user/ideas/new" className='shadow-none px-3 rounded-lg text-nowrap mr-3'>Share Your Idea</Button>
                    <Button className='btn-icon shadow-none'>
                        <em className='bx bxs-envelope font-size-h5'></em>
                    </Button>
                </div>

                <Dropdown className='header-profile ml-3'>
                    <Dropdown.Toggle as={'span'} role='button'>
                        <Avatar img_src={user.avatar ? user.avatar : ''} name={user.full_name ? user.full_name : ''} size='sm' />
                    </Dropdown.Toggle>
                    <Dropdown.Menu align={'right'} className='min-w-200px'>
                        <div className='flex-column d-flex align-items-center p-3'>
                            <Link to='/user/profile' style={{ textDecoration: 'none', color: 'inherit' }}>
                                <Avatar img_src={user.avatar} name={user.full_name} size='lg' className='mb-2' />
                            </Link>
                            <h5 className='font-size-h6'>{user.display_name}</h5>
                        </div>
                        <div className="d-md-none px-4 mb-3">
                            <Button variant='outline-primary' size='sm' as={Link} to="/user/ideas/new" className='shadow-none px-3 btn-block rounded-lg mr-3'>Share Your Idea</Button>
                        </div>
                        <Dropdown.Item as={Link} to="/user/messages" className='nav-link d-md-none'>
                            <em className='icon bx bxs-envelope'></em>
                            <span>Messages</span>
                        </Dropdown.Item>
                        <Dropdown.Item as={Link} to="/user/profile" className='nav-link'>
                            <em className='icon bx bx-user-circle'></em>
                            <span>Profile</span>
                        </Dropdown.Item>
                        <Dropdown.Item as={Link} to="/user/settings" className='nav-link'>
                            <em className='icon bx bx-cog'></em>
                            <span>Settings</span>
                        </Dropdown.Item>
                        <Dropdown.Item as={Link} to='/user/logout' className='nav-link' onClick={e => {
                            e.preventDefault();
                            signOutUser();
                        }}>
                            <em className='icon bx bx-log-out'></em>
                            <span>Sign Out</span>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </>
    )
}

const Layout = () => {
    const navigate = useNavigate();
    const [invalidFormInput, setInvalidFormInput] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const user_context = useContext(Context);
    const authUser = user_context.authUser;

    useEffect(() => {
        setInvalidFormInput(false);
        setShowSearch(false);

    }, [user_context])
    const searchIdea = e => {
        e.preventDefault();
        let form = Object.fromEntries(new FormData(e.target).entries())
        let input = e.target.querySelector('input')
        if (input) input.classList.add('validate-input')
        if (form.search && form.search.length > 2) {
            setInvalidFormInput(false)
            navigate(`/search/${form.search}`)
        } else {
            setInvalidFormInput(true)
        }
    }


    const toggleSearch = (show = true) => {
        setShowSearch(show === true);
    }
    return (
        <>
            <SEO title='Sparkuz - Share an idea' />
            <header className='app-header'>
                <Container className='navbar align-items-end'>
                    <Button variant='none' className='menu-toggle'>
                        <em className='icon bx bx-menu font-size-h2'></em>
                    </Button>
                    <Logo className='flex-shrink-0' />

                    <nav className='app-nav d-flex align-items-center ml-4 mt-auto'>
                        <ul className='nav app-menu d-none'>
                            <li className='nav-item'>
                                <Link to="/ideas" className='nav-link'>Ideas</Link>
                            </li>
                        </ul>
                    </nav>
                    <div className='mx-auto'>
                        <Button variant='light' className='app-search-toggle bg-transparent rounded-pill px-4' onClick={e => {
                            e.preventDefault();
                            toggleSearch(true);
                        }} style={{
                            transition: '.3s ease',
                            opacity: (showSearch ? 0 : 1),
                            visibility: (showSearch ? 'hidden' : 'visible')
                        }}>
                            <i className='bx bx-search mr-2'></i>
                            <span>Search</span>
                        </Button>
                        <form method='GET' action='search/' className={'header-search-form flex-column min-w-200px' + (showSearch?' show':'')} onSubmit={searchIdea} onClick={e => {
                            e.preventDefault();
                            if (!e.target.closest('.search-body')) {
                                setShowSearch(false);
                            }
                        }}>
                            <div className='search-body'>
                                <Button variant='light' className='btn-icon'>
                                    <i className='bx bx-search'></i>
                                </Button>
                                <FormControl name='search' className={'h-auto bg-light rounded-lg' + (invalidFormInput ? ' is-invalid' : '')} placeholder='Search idea' onChange={e => {
                                    let val = e.target.value;
                                    if (e.target.classList.contains('validate-input')) {
                                        if (val.length > 2) {
                                            e.target.classList.remove('is-invalid')
                                        } else {
                                            e.target.classList.add('is-invalid')
                                        }
                                    }
                                }} />
                            </div>
                        </form>
                    </div>


                    <div className='align-items-center d-flex justify-content-end'>
                        {user_context.isLoggedin ? <SignedInHeader auth_user={authUser} signOutUser={e => {
                            user_context.signOutUser()
                            navigate('/auth/login');
                        }} /> : <div className='d-flex'>
                            <Button as={Link} to="/auth/login" className='btn-light border rounded-pill shadow-none px-3 bg-white mr-3'>Login</Button>
                            <Button as={Link} to="/auth/signup" className='btn-primary rounded-pill shadow-none px-3 btn-dim'>Signup</Button>
                        </div>}
                    </div>
                </Container>
            </header>
            <Outlet auth_user={authUser} />
        </>
    )
}
export default Layout;