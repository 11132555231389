import { EyeFilled, HeartFilled, MessageFilled } from '@ant-design/icons';
import React from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SEO from "../../components/SEO";
import { Avatar } from '../../components/Widgets';
export const Idea = () => {
    const idea = {
        id: 4,
        title: 'Peer to peer Delivery',
        summary: 'p2p for delivery, why not utilise the fact that people always have to travel, why cnt they do a delivery',
        category: 'Peer2peer',
        tag: '',
        meta: {
            views: 69,
            likes: 144,
            comments: 21
        },
        author: {
            name: 'Stephen Abila',
            email: 'jameskiza@gmail.com',
            avatar: ''
        },
        comments:[
            {
                id:'sajiq',
                name:'Ja Ja',
                avatar:'',
                message:'Amazing idea. I love it',
                reaction:'',
                date:''
            },
            {
                id:'ashuh',
                name: 'Ben',
                avatar: '',
                message: 'Makes Sense',
                reaction: '',
                date: ''
            },
            {
                id: 'asu1h',
                name: 'Nick',
                avatar: '',
                message: 'How do you handle security?',
                reaction: '',
                date: ''
            },
            {
                id: '3hua9h',
                name: 'John Alex',
                avatar: '',
                message: 'Amazing idea. I love it',
                reaction: '',
                date: ''
            },
            {
                id: '21ihak',
                name: 'Erik',
                avatar: '',
                message: 'This isn\'t feasible',
                reaction: '',
                date: ''
            }
        ]
    }

return (
    <>
        <SEO title={idea.title}
            description={idea.summary} />
        <Container>
            <Row className='my-4 justify-content-center'>
                <Col lg={10}>
                    <Row className='justify-content-between'>
                        <Col md={7}>
                            <div className='d-flex align-items-start user-card p-3'>
                                <Avatar img_src={idea.author.avatar} size='lg' name={idea.author.name} className='mr-3' />
                                <div className='user-info flex-column d-flex ml-2 line-height-md'>
                                    <Link className='font-size-base text-decoration-none'>{idea.author.name}</Link>
                                    <h5 className='text-dark font-size-h4'>{idea.title}</h5>
                                    <div className="d-flex justify-content-around">
                                        <span className='align-items-center d-flex text-dark mr-3'>
                                            <EyeFilled className='mr-2' />
                                            <span className='font-size-xs'>{idea.meta.views}</span>
                                        </span>
                                        <span className='align-items-center d-flex text-dark mx-3'>
                                            <HeartFilled className='mr-2' />
                                            <span className='font-size-xs'>{idea.meta.likes}</span>
                                        </span>
                                        <span className='align-items-center d-flex text-dark ml-3'>
                                            <MessageFilled className='mr-2' />
                                            <span className='font-size-xs'>{idea.meta.comments}</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='mb-4'>
                                    <h6 className='font-size-h5'>Overview</h6>
                                    <span className='font-size-sm'>Parcel delivery is a big issue in African and this has a negative effect on business because lot of business have their backbone on effective delivery of their products to customers</span>
                                </div>
                                <div className='mb-4'>
                                    <h6 className='font-size-h5'>The Problem / Challenge</h6>
                                    <span className='font-size-sm'>Parcel delivery is a big issue in African and this has a negative effect on business because lot of business have their backbone on effective delivery of their products to customers</span>
                                </div>
                                <div className='mb-4'>
                                    <h6 className='font-size-h5'>Proposed Solution</h6>
                                    <span className='font-size-sm'>An app that you are able to see an individual going to a location and who is willing to make a delivery for a fee</span>
                                </div>
                                <div className='mb-4'>
                                    <h6 className='font-size-h5'>Who would benefit from this idea</h6>
                                    <span className='font-size-sm'>Students, Commercial Drivers</span>
                                </div>
                                <div className='mb-4'>
                                    <h6 className='font-size-h6'>Uploaded Files</h6>
                                    <ul>
                                        <li>
                                            p2p.pdf
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} md={5}>
                            <h5>Feedback</h5>
                            <Form className='mb-5'>
                                <Form.Group>
                                    <Form.Control as='textarea' rows='4' placeholder='Share your thoughts' className='bg-light border-0'></Form.Control>
                                </Form.Group>
                            </Form>
                            <div className='idea-comments'>
                                {idea.comments.map(comment => {
                                    return <div className='idea-comment mb-2' key={comment.id}>
                                        <div className='d-flex align-item-start'>
                                            <Avatar name={comment.name} size='sm' className='mr-2' />
                                            <div className='bg-light py-2 px-3 flex-grow-1 position-relative'>
                                                <span className='position-absolute text-soft top-0 right-0 font-size-xs p-2'>
                                                    2hr
                                                </span>
                                                <span className='font-size-base text-dark font-weight-bold'>{comment.name}</span>
                                                <div className='font-size-sm text-muted'>
                                                    {comment.message}
                                                </div>
                                            </div>
                                        </div>
                                        <ul className='nav font-size-xs justify-content-end'>
                                            <li className='nav-item'>
                                                <Link className='nav-link'>Like</Link>
                                            </li>
                                            <li className='nav-item'>
                                                <Link className='nav-link'>Reply</Link>
                                            </li>
                                        </ul>
                                    </div>
                                })}

                            </div>
                        </Col>
                    </Row>
                </Col>

            </Row>
        </Container>
    </>

)
}
