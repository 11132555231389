import React, { useState } from "react";
import { Button, Card, Container } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { API, Utils } from "../../../components/Utilities";
import { Logo } from "../../../components/Logo";
import "../auth.css";
import { AuthCode } from "../../../components/Widgets";

export const EmailVerification = () => {
	const navigate = useNavigate();
	const [resetParams, setResetParams] = useState({
		email_address: "Soflyben18@gmail.com",
		new_password: "",
		reset_token: "",
	});

	const verify_email = (e) => {
		e.preventDefault();
		console.log(resetParams);
		let toast_opts = {
			title: "Account login",
			type: "error",
			text: "Failed to complete request",
		};

		API.post("/auth/password/reset", {}).then((resp) => {
			console.log(resp);
			if (resp.status && resp.code === 200) {
				navigate("/user/");
			} else {
				toast_opts.text = resp.message
					? resp.message
					: "Failed to complete request";
				Utils.toast(toast_opts);
			}
		});
	};
	const resend_auth_token = (e) => {
		e.preventDefault();
		console.log("Resend code");
	};
	return (
		<>
			<Container className="auth-wrapper">
				<Card className="border-0 max-w-400px mx-auto text-center py-3 w-100">
					<div className="mb-3">
						<Logo />
					</div>
					<div className="mb-4">
						<h1 className="auth-title font-size-h3">
							Let's Verify your email
						</h1>
						<span className="auth-subtitle">
							You’re almost there!, we sent an email to
						</span>
					</div>
					<p className="text-dark font-weight-bold font-size-lg">
						{resetParams.email_address}
					</p>
					<form
						method="POST"
						action="./"
						style={{ marginBottom: "1rem" }}
						onSubmit={verify_email}>
						<AuthCode
							name="reset_token"
							onChange={(val) => {
								console.log(val);
								setResetParams({
									...resetParams,
									reset_token: val,
								});
							}}
							onComplete={(val, index) => {
								console.log('Show password');
								// Show New Password input
							}}
						/>
						<input
							type="hidden"
							name="email_address"
							value={resetParams.email_address}
						/>
						<input
							type="submit"
							style={{ display: "none" }}
						/>
					</form>

					<p className="text-soft my-3">
						Just click on the link in that email to complete your
						sign up. If you don’t see it, you may need to{" "}
						<strong className="text-dark">check your spam</strong>{" "}
						folder
					</p>

					<div className="my-3">
						<p className="m-0">Still can’t find the email?</p>
						<Button
							type="button"
							variant="primary"
							className="my-2 btn-block mx-auto max-w-300px py-2 font-weight-bold font-size-lg"
							onClick={resend_auth_token}>
							Resend Email
						</Button>
						<p>
							Need help?{" "}
							<Link
								to="#"
								className="link">
								Contact Us
							</Link>
						</p>
					</div>
				</Card>
			</Container>
		</>
	);
};
