import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Error404 from './pages/error/';

import Layout from './components/Layout';

import App from './App';
import { Idea as IdeaDetailed } from './pages/idea';
import Ideas from './pages/ideas';
import { EmailVerification, Login, ResetPassword, SignUp } from './pages/auth';
import {
    Connections,
    EditIdea,
    Idea,
    ListIdeas,
    NewIdea,
    Profile,
    ProfileIdeas,
    SavedIdeas,
    Settings,
} from './pages/user/';
const routes = [
    {
        path: '/',
        element: <Layout />,
        // exact: true,
        children: [
            {
                path: '*',
                element: <Error404 />
            },
            {
                path: '/',
                element: <App />,
            },
            {
                path: '/ideas/:id',
                element: <IdeaDetailed />,
                exact: true
            },
            {
                path: '/ideas/',
                element: <Ideas />,
                exact: false
            },
            {
                path: '/search/:search',
                element: <Ideas />,
                exact: false
            }
        ]
    },
    {
        path: '/user',
        element: <Layout />,
        //exact: true,
        children: [
            {
                element: <Profile />,
                children: [
                    {
                        index: true,
                        element: <ProfileIdeas />
                    }
                ]
            },
            {
                path: 'profile',
                element: <Profile />,
                children: [
                    {
                        index: true,
                        element: <ProfileIdeas />
                    },
                    {
                        path: 'ideas',
                        element: <ProfileIdeas />
                    },
                    {
                        path: 'connections',
                        element: <Connections />
                    },
                    {
                        path: 'saved-ideas',
                        element: <SavedIdeas />
                    },
                ]
            },
            {
                path: 'settings',
                element: <Settings />
            },
            {
                path: 'ideas',
                children: [
                    {
                        path: './',
                        element: <ListIdeas />
                    },
                    {
                        path: ':id',
                        element: <Idea />
                    },
                    {
                        path: 'edit/:id',
                        element: <EditIdea />
                    },
                    {
                        path: 'new',
                        element: <NewIdea />
                    }
                ]
            }
        ]
    },
    {
        path: 'auth/',
        children: [
            {
                index: true,
                element: <Login />
            },
            {
                path: 'login',
                element: <Login />
            },
            {
                path: 'signup',
                element: <SignUp />
            },
            {
                path: 'email-verification',
                element: <EmailVerification />
            },
            {
                path: 'reset-password',
                element: <ResetPassword />
            }
        ]
    },
    {
        path: '*',
        element: <Error404 />
    }
];

export const AppRoutes = () => {
    return (
        <BrowserRouter>
            <Routes>
                {routes.map((r, i) => {
                    return <Route key={i} path={r.path} element={r?.element} exact={r?.exact} index={r?.index}>
                        {r.children && r.children.map((sr, j) => {
                            return <Route key={i + '' + j} path={sr.path} element={sr?.element} exact={sr?.exact} index={sr?.index}>
                                {sr.children && sr.children.map((ssr, k) => {
                                    return <Route key={i + '' + j + '' + k} path={ssr.path} element={ssr?.element} exact={ssr?.exact} index={ssr?.index} />
                                })}
                            </Route>
                        })}
                    </Route>
                })}
            </Routes>
        </BrowserRouter >
    )
}