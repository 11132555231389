import React, { useContext, useEffect, useState } from 'react';
import { Container, Nav, Tab } from 'react-bootstrap';
import SEO from '../../../components/SEO';

import { Context } from '../../../contexts/UserContext';

import { ChangePassword } from './components/change-password';
import { ProfileUpdate } from './components/update-profile';
import { API } from '../../../components/Utilities';

export const Settings = () => {

  const user_context = useContext(Context);
  const [profile, setProfile] = useState(user_context.authUser);
  useEffect(() => {
    API.get('account/profile/fetch').then(resp => {
      if (resp && resp.status === true) {
        setProfile(resp.data);
      }
    })

  }, [user_context])


  return (
    <>
      <SEO title='User - Settings' />
      <Container className='max-w-400px my-4'>
        <Tab.Container defaultActiveKey="profile">
          <Nav variant="pills" className='mb-4'>
            <Nav.Item className='mr-4'>
              <Nav.Link eventKey="profile">Profile</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="password">Password</Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="profile">
              <div className='py-4'>
                <h4>{profile.full_name} / Edit Profile</h4>
                <p className='font-size-base' style={{
                  color: '#0E0A14'
                }}>Edit your account details bellow</p>
              </div>

              <ProfileUpdate profile={profile} updateProfile={user_context.setAuthUser} />
            </Tab.Pane>
            <Tab.Pane eventKey="password">
              <ChangePassword />
            </Tab.Pane>
          </Tab.Content>

        </Tab.Container>
      </Container>
    </>
  )
}
