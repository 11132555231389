import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';
export const StepFour = ({ onSubmit }) => {
    return (
        <>
            <div className='mb-4'>
                <h6 className='font-size-h5'>Overview</h6>
                <span className='text-soft font-size-sm'>Parcel delivery is a big issue in African and this has a negative effect on business because lot of business have their backbone on effective delivery of their products to customers</span>
            </div>
            <div className='mb-4'>
                <h6 className='font-size-h5'>The Problem / Challenge</h6>
                <span className='text-soft font-size-sm'>Parcel delivery is a big issue in African and this has a negative effect on business because lot of business have their backbone on effective delivery of their products to customers</span>
            </div>
            <div className='mb-4'>
                <h6 className='font-size-h5'>Proposed Solution</h6>
                <span className='text-soft font-size-sm'>An app that you are able to see an individual going to a location and who is willing to make a delivery for a fee</span>
            </div>
            <div className='mb-4'>
                <h6 className='font-size-h5'>Who would benefit from this idea</h6>
                <span className='text-soft font-size-sm'>Students, Commercial Drivers</span>
            </div>
            <div className='mb-4'>
                <h6 className='font-size-h6'>Uploaded Files</h6>
                <Table size='sm' >
                    <tbody>
                        <tr>
                            <td>
                                <span>p2p.pdf </span>
                            </td>
                            <td><Link>Preview</Link></td>
                        </tr>
                    </tbody>
                </Table>

            </div>
        </>
    )
}