import { useGoogleLogin } from "@react-oauth/google";
import React, { useContext, useState } from "react";
import {
	Button,
	Card,
	Container,
	FormControl,
	FormLabel,
	Spinner,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { API, Utils } from "../../../components/Utilities";
import { Context } from "../../../contexts/UserContext";
import { Logo } from "../../../components/Logo";
import "../auth.css";
import { useValidateFormFields } from "../../../hooks/useValidateFormFields";
export const Login = () => {
	const { validatePassword, validateEmail } = useValidateFormFields();
	const navigate = useNavigate();
	const user_context = useContext(Context);
	const [showPassword, setShowPassword] = useState(false);
	const [btnLoading, setBtnLoading] = useState(false);
	const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false);
	const [formParams, setFormParams] = useState({
		email_address: "",
		password: "",
	});
	const [validStates, setValidStates] = useState({
		email_address: {
			invalid: false,
			message: "",
		},
		password: {
			invalid: false,
			message: "",
		},
	});
	const login = (e) => {
		e.preventDefault();
		setHasBeenSubmitted(true);
		let toast_opts = {
			title: "Account login",
			type: "error",
			text: "Failed to complete request",
		},
			err_obj = validStates;
		if (formParams.email_address.length === 0) {
			toast_opts.text = "Email is required";
			err_obj.email_address = {
				invalid: true,
				message: toast_opts.text,
			};
		} else if (formParams.password.length === 0) {
			toast_opts.text = "Password is required";
			err_obj.password = {
				invalid: true,
				message: toast_opts.text,
			};
		} else {
			setBtnLoading(true);
			API.post("/auth/login", formParams).then((resp) => {
				setBtnLoading(false);
				if (resp.status && resp.code === 200) {
					console.log(resp.data);
					let _login = user_context.signInUser(resp.data);
					if (_login === true) {
						navigate("/user/");
					} else {
						toast_opts.text = _login.error.messsage;
						Utils.toast(toast_opts);
					}
				} else {
					toast_opts.text = resp.message
						? resp.message
						: "Failed to complete request";
					Utils.toast(toast_opts);
				}
			});
			return;
		}
		setValidStates(err_obj);
		Utils.toast(toast_opts);
	};
	const validate_input = (e) => {
		let elm = e.target,
			name = elm.getAttribute("name"),
			value = elm.value,
			reject_input = false,
			err_obj = {
				invalid: false,
				message: "",
			};
		if (name === "email_address") {
			let val_email = validateEmail(value.trim())
			err_obj = {
				invalid: !val_email.valid,
				message: val_email.message
			};
		} else if (name === "password") {
			let validate_value = validatePassword(value);
			err_obj = {
				invalid: !validate_value.valid,
				message: validate_value.message
			}
		}
		setValidStates({ ...validStates, [name]: err_obj });
		if (!reject_input) setFormParams({ ...formParams, [name]: value });
	};
	const googleLogin = useGoogleLogin({
		onSuccess: (gresp) => {
			API.get(
				`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${gresp.access_token}`,
				{
					headers: {
						Authorization: `Bearer ${gresp.access_token}`,
					},
				}
			).then((res) => {
				//  googleID, gmail, name, etc...
				console.log(res.data);
			});
		},
		onError: (error) => console.log("Login Failed:", error),
	});
	return (
		<>
			<Container className="auth-wrapper">
				<Card className="border-0 max-w-400px mx-auto text-center py-3 w-100">
					<div className="mb-3">
						<Logo />
					</div>
					<div className="mb-4">
						<h1 className="auth-title">Welcome Back</h1>
						<span className="auth-subtitle">
							It's time to catch-up
						</span>
					</div>

					<Button
						variant="outline-light"
						size="lg"
						className="google-btn"
						onClick={(e) => {
							e.preventDefault();
							return googleLogin();
						}}>
						Continue with Google
					</Button>

					<div className="alt-auth">
						<span className="alt-auth-text">
							or Sign in with email
						</span>
					</div>
					<form
						className="text-left auth-form"
						onSubmit={login}>
						<FormLabel className="mb-4">
							Email Address
							<FormControl
								type="email"
								name="email_address"
								placeholder="Enter your email address"
								value={formParams.email_address}
								onChange={validate_input}
								isInvalid={
									hasBeenSubmitted &&
									validStates.email_address.invalid
								}
							/>
							{hasBeenSubmitted &&
								validStates.email_address.invalid && (
									<span className="input-val-msg">
										{validStates.email_address.message}
									</span>
								)}
						</FormLabel>
						<div className="mb-4 pb-2">
							<FormLabel className="mb-0">
								Password
								<div
									className={
										"password-input" +
										(showPassword ? " shown" : "")
									}>
									<FormControl
										type={
											showPassword ? "text" : "password"
										}
										name="password"
										placeholder="Enter your Password"
										value={formParams.password}
										onChange={validate_input}
										className="bg-transparent"
										isInvalid={
											hasBeenSubmitted &&
											validStates.password.invalid
										}
									/>
									{hasBeenSubmitted &&
										validStates.password.invalid && (
											<span className="input-val-msg position-absolute top-100">
												{validStates.password.message}
											</span>
										)}
									<div className="position-absolute top-0 right-0 h-100 zindex-2 d-flex px-2">
										<Button
											type="button"
											variant="light"
											className="my-auto btn-icon password-toggle"
											onClick={(e) => {
												e.preventDefault();
												setShowPassword(!showPassword);
											}}></Button>
									</div>
								</div>
							</FormLabel>
							<div className="text-right">
								<Link to="/auth/reset-password">
									Forgot Password?
								</Link>
							</div>
						</div>

						<Button
							type="submit"
							className="btn-primary btn-submit align-items-center d-flex"
							disabled={btnLoading}>
							{btnLoading ? (
								<>
									<Spinner
										size="sm mr-2"
										className="my-auto"
										animation="border"
										role="status">
										<span className="sr-only">
											Loading...
										</span>
									</Spinner>
									Logging in...
								</>
							) : (
								"Login"
							)}
						</Button>
						<p className="text-center mb-0 mt-2">
							Don't have an account?{" "}
							<Link to="/auth/signup">Sign Up</Link>
						</p>
					</form>
				</Card>
			</Container>
		</>
	);
};
