import React from 'react'
import { Link } from 'react-router-dom'
import { Row,Col,Card, Container } from 'react-bootstrap'
export const ProfileIdeas = () => {
    const ideas = [
        {
            id: 1,
            title: 'Diary for life',
            summary: 'As we grow old we loose our memory, what if we can store information about our self, so future care provides do bot have to guuess...',
            category: 'Agro',
            tag: '',
            meta: {
                views: 69,
                likes: 144,
                comments: 21
            },
            author: {
                name: 'Nikola Coco',
                email: 'nikolacoco@gmail.com',
                avatar: ''
            }
        },
        {
            id: 2,
            title: 'Shared Room',
            summary: 'is possible that two individuals can share a room for 12 hours each, if one works night and one works day shift,',
            category: 'Peer2peer',
            tag: '',
            meta: {
                views: 69,
                likes: 144,
                comments: 21
            },
            author: {
                name: 'James Kiza',
                email: 'jameskiza@gmail.com',
                avatar: ''
            }
        },
        {
            id: 3,
            title: 'Peer to peer Loan',
            summary: 'peer to peer loan has been around for a while, but there is a better way to do it',
            category: 'Agro',
            tag: '',
            meta: {
                views: 69,
                likes: 144,
                comments: 21
            },
            author: {
                name: 'John Ray',
                email: 'jameskiza@gmail.com',
                avatar: ''
            }
        }
    ]
  return (
    <Container className='idea-listing'>
          <Row className='justify-content-between align-items-stretch'>
              {ideas.map(idea => {
                  return <Col sm={6} md={4} className='py-3' key={idea.id}>
                      <Card className='idea-card h-100'>
                          <Link to={'/ideas/' + idea.id} className='link-inherit'>
                              <span variant='secondary' className='badge idea-label'>{idea.category}</span>
                              <Card.Body className='text-center'>
                                  <h5 className='title text-center font-size-h6'>{idea.title}</h5>
                                  <p className="m-0 text-soft font-size-xs line-height-lg">{idea.summary}</p>
                              </Card.Body>
                          </Link>
                      </Card>
                  </Col>
              })}
          </Row>
    </Container>

  )
}
