import { Button, Form } from "react-bootstrap";
import { API, Utils } from "../../../../../components/Utilities";
import { useNotify } from "../../hooks/useNotify";
export const ChangePassword = () => {
    const { notify } = useNotify();
    const updatePassword = (e) => {
        e.preventDefault();
        let params = Object.fromEntries(new FormData(e.target).entries());
        let val_password = Utils.validate_password(params.new_password),
            toast_opts = {
                type: "error",
                title: "Change password",
                text: "An error occured",
            };
        if (val_password !== true) {
            toast_opts.text = val_password;
            Utils.toast(toast_opts);
            return false;
        } else if (params.confirm_password !== params.new_password) {
            toast_opts.text = 'Passwords do not match';
            Utils.toast(toast_opts);
            return false;
        }

        API.post("auth/password/change", params).then((resp) => {
            console.log(resp);
            if (resp.status === true) {
                notify("Change updated");
                e.target.reset();
            } else {
                Utils.toast({
                    type: "error",
                    text: resp.message ? resp.message : "",
                });
            }
        });
    };
    return (
        <Form
            method="POST"
            action="#"
            onSubmit={updatePassword}>
            <Form.Group className="form-group">
                <Form.Label>
                    Current Password
                    <Form.Control name="password" />
                </Form.Label>
            </Form.Group>
            <Form.Group className="form-group">
                <Form.Label>
                    New Password
                    <Form.Control name="new_password" />
                </Form.Label>
            </Form.Group>
            <Form.Group className="form-group">
                <Form.Label>
                    Confirm Password
                    <Form.Control name="confirm_password" />
                </Form.Label>
            </Form.Group>

            <Button
                type="submit"
                size="lg"
                className="btn-submit btn-block">
                Change Password
            </Button>
        </Form>
    );
};