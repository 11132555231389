import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
//import React from "react";

import { Context } from "./contexts/UserContext";
import Home from './pages/home';

import 'react-toastify/dist/ReactToastify.min.css';
function App() {
  const navigate = useNavigate();
  const url_path = useLocation();
  const user_context = useContext(Context);

  useEffect(() => {
    if (!user_context.isLoggedin && url_path.pathname.includes('/user/')) {
      navigate('/auth/');
    }
  }, [user_context, url_path, navigate])

  return (
    <Home isLoggedin />
  );
}
export default App;