import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Dropdown, Row } from 'react-bootstrap';
import SEO from "../../components/SEO";

import { Link, useParams, useSearchParams } from "react-router-dom";
import { UserCard } from '../../components/Widgets';
const categories = [
    'Fintech', 'Delivery', 'Agriculture', 'Waste Management', 'Software', 'Startup', 'Market Places'
]
const ideas = [
    {
        id: 1,
        title: 'Diary for life',
        summary: 'As we grow old we loose our memory, what if we can store information about our self, so future care provides do bot have to guuess...',
        category: 'Agro',
        tag: '',
        meta: {
            views: 69,
            likes: 144,
            comments: 21
        },
        author: {
            name: 'Nikola Coco',
            email: 'nikolacoco@gmail.com',
            avatar: ''
        }
    },
    {
        id: 2,
        title: 'Shared Room',
        summary: 'is possible that two individuals can share a room for 12 hours each, if one works night and one works day shift,',
        category: 'Peer2peer',
        tag: '',
        meta: {
            views: 69,
            likes: 144,
            comments: 21
        },
        author: {
            name: 'James Kiza',
            email: 'jameskiza@gmail.com',
            avatar: ''
        }
    },
    {
        id: 3,
        title: 'Peer to peer Loan',
        summary: 'peer to peer loan has been around for a while, but there is a better way to do it',
        category: 'Agro',
        tag: '',
        meta: {
            views: 69,
            likes: 144,
            comments: 21
        },
        author: {
            name: 'John Ray',
            email: 'jameskiza@gmail.com',
            avatar: ''
        }
    },
    {
        id: 4,
        title: 'Peer to peer Delivery',
        summary: 'p2p for delivery, why not utilise the fact that people always have to travel, why cnt they do a delivery',
        category: 'Peer2peer',
        tag: '',
        meta: {
            views: 69,
            likes: 144,
            comments: 21
        },
        author: {
            name: 'Stephen Abila',
            email: 'jameskiza@gmail.com',
            avatar: ''
        }
    }
]
const Ideas = () => {
    const qry_params = useSearchParams();
    const params = useParams();
    const [_search, setSearch] = useState(null)
    const [_category, setCategory] = useState('all')
    useEffect(() => {
        if (params.search) {
            setSearch(params.search)
        }
        if (qry_params.c) {
            setCategory(qry_params.c)
        }
        console.log(params, qry_params);
    }, [params, qry_params])

    return (
        <>
            <SEO title='Sparkuz - Ideas'
                description='Share an Idea, Create a Buzz' />
            <Container className='mt-5'>
                {_search && <h4 className=''>Search: {_search}</h4>}
                <div className='d-flex justify-content-between align-items-end my-4'>
                    <div className='max-w-250px position-relative zindex-5'>
                        <Dropdown className='no-caret'>
                            <Dropdown.Toggle as={Button} variant='light' className='bg-white border px-3'>
                                <span>Fin-Tech</span>
                                <i className='icon bx bx-chevron-down ml-2'></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className='py-3 min-w-200px' align='start' >
                                <Dropdown.Header>
                                    <span className='text-dark font-weight-bold'>Categories</span>
                                </Dropdown.Header>
                                <form className='list-group list-group-flush font-size-sm border-0 m-0 p-0 max-h-400px overflow-auto'>
                                    {categories.map((category, i) => {
                                        return <label key={category} className={'list-group-item list-group-item-action d-flex align-item-center m-0 py-3 ' + (i === (categories.length - 1) ? 'border-0' : '')} role='button'>
                                            <div className='mr-2'>
                                                <input type='checkbox' className='' />
                                            </div>
                                            <span>{category}</span>
                                        </label>
                                    })}
                                    <Dropdown.Divider></Dropdown.Divider>
                                    <div className='px-2 text-right'>
                                        <Dropdown.Item variant='primary' as={Button} size='sm'>Filter Categories</Dropdown.Item>
                                    </div>
                                </form>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className='d-none d-md-flex overflow-auto mx-3 flex-grow-1 px-2'>
                        <nav className='nav nav-pills flex-nowrap'>
                            {['All', 'Tech', 'Agro', 'Startup', 'Fintech', 'Peer2peer', 'Delivery', 'Cryto', 'Others'].map((c, i) => {
                                return <Link key={c} to='#' className={'nav-link px-3 py-1 text-dark' + (_category.toLowerCase() === c.toLowerCase() && ' bg-light')}>{c}</Link>
                            })}
                        </nav>
                    </div>
                    <div className='max-w-250px'>
                        <Dropdown className='no-caret'>
                            <Dropdown.Toggle as={'span'} role='button' className='btn btn-light bg-white border'>
                                <span>Most Likes</span>
                                <i className='icon bx bx-filter font-size-h4 ml-2'></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu align='end' className='text-right border' onSelect={e => {
                                // e.preventDefault()
                                console.log(e, 'select');
                            }}>
                                <Dropdown.Item as={Link} to='?sort=likes&order=desc' className='nav-link'>
                                    Most Likes
                                </Dropdown.Item>
                                <Dropdown.Item as={Link} to='?sort=comments&order=desc' className='nav-link'>
                                    Most comments
                                </Dropdown.Item>
                                <Dropdown.Item as={Link} to='?sort=date&order=desc' className='nav-link'>
                                    Newest
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
                <section className='idea-listing mt-0'>
                    <Row className='justify-content-between align-items-stretch'>
                        {ideas.map(idea => {
                            return <Col sm={6} md={4} lg={3} className='py-3' key={idea.id}>
                                <Card className='idea-card'>
                                    <Link to={'/ideas/' + idea.id} style={{
                                        color: 'inherit',
                                        textDecoration: 'none'
                                    }}>
                                        <UserCard img_src={idea.author.avatar} name={idea.author.name} email={idea.author.email} />
                                        <span className='badge idea-label'>{idea.category}</span>
                                        <Card.Body className='text-center'>
                                            <h5 className='title text-center font-size-h6'>{idea.title}</h5>
                                            <p className="m-0 text-soft font-size-xs line-height-lg">{idea.summary}</p>
                                        </Card.Body>
                                        <Card.Footer>
                                            <div className="d-flex justify-content-around">
                                                <span className='align-items-center d-flex text-secondary mx-3'>
                                                    <em className='mr-2 bx bxs-show' ></em>
                                                    <span className='font-size-xs'>{idea.meta.views}</span>
                                                </span>
                                                <span className='align-items-center d-flex text-secondary mx-3'>
                                                    <em className='mr-2 icon bx bxs-heart'></em>
                                                    <span className='font-size-xs'>{idea.meta.likes}</span>
                                                </span>
                                                <span className='align-items-center d-flex text-secondary mx-3'>
                                                    <em className='mr-2 icon bx bxs-message-alt-dots' ></em>
                                                    <span className='font-size-xs'>{idea.meta.comments}</span>
                                                </span>
                                            </div>
                                        </Card.Footer>
                                    </Link>
                                </Card>
                            </Col>
                        })}
                    </Row>
                </section>
            </Container>
        </>
    )
}
export default Ideas;