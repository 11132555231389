import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Error404Img from '../../assets/img/error-404.svg';
import SEO from "../../components/SEO";


const Error404 = () => {
    const navigate = useNavigate();
    return (
        <>
            <SEO title='Sparkuz - Page not found' />
            <div className='min-h-100vh d-flex align-items-center justify-content-center py-5'>
                <div className='max-w-500px text-center d-flex align-items-center flex-column px-3'>
                    <img src={Error404Img} alt="Error page not found" className='w-100 mb-4' />
                    <span className='font-size-h5 mb-sm-3 mb-5'>This page does not exist, it might have been moved somewhere else.</span>
                    <Button onClick={e => {
                        e.preventDefault();
                        navigate(-1);
                    }} className='pl-3 pr-4'>
                        <i className='icon font-size-lg mr-2 bx bx-left-arrow-alt'></i>Go back</Button>
                </div>
            </div>
        </>

    )
}

export default Error404