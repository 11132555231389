import React, { useContext } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { Link, NavLink, Outlet } from 'react-router-dom'
import SEO from '../../../components/SEO'
import { Avatar } from '../../../components/Widgets'
import { Context } from '../../../contexts/UserContext'

export const Profile = () => {
  const user_context = useContext(Context);

  const user_profile = user_context.authUser;
  console.log(user_profile)
  return (
    <>
      <SEO title='User - Profile' />
      <Container>
        <Row className='justify-content-center my-4'>
          <Col md={10} lg={8}>
            <div className='d-flex p-3'>
              <Avatar name={user_profile.full_name} img_src={user_profile.avatar} size='xxl' className='overflow-hidden mr-2' />
              <div className='user-info ml-2 d-flex flex-column align-items-start line-height-md'>
                <span className='font-size-h5 font-weight-bold'>{user_profile.full_name}</span>
                <span className='text-soft font-size-sm font-weight-bold mb-3'>{user_profile.email_address}</span>
                <Button as={Link} variant='light' className='px-3 bg-white border' size='sm' to='/user/settings'>Edit Profile</Button>
              </div>
            </div>
            <br />
            <ul className='nav nav-tabs nav-line mb-3'>
              <li className='nav-item'>
                <NavLink to="/user/profile/ideas" className='nav-link'>Ideas 10</NavLink>
              </li>
              <li className='nav-item'>
                <NavLink to="/user/profile/connections" className='nav-link'>100 Connections</NavLink>
              </li>
              <li className='nav-item'>
                <NavLink to="/user/profile/saved-ideas" className='nav-link'>Saved Ideas</NavLink>
              </li>
            </ul>
            <div className=''>
              <Outlet />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}
