import React from 'react';
import { FormControl, FormGroup, FormLabel } from 'react-bootstrap';

export const StepTwo = ({ onSubmit }) => {
    return (
        <>
            <FormGroup className='form-group mb-4'>
                <FormLabel className='mb-4'>
                    <h6 className='font-size-h5'>Overview</h6>
                    <span className='text-soft font-size-sm'>Please provide general information about this idea, like a background of the problem so we can have a better understanding of this</span>
                    <FormControl as='textarea' name='overview' rows={5} className='shadow-none' />
                </FormLabel>
            </FormGroup>
        </>
    )
}
