import "cropperjs/dist/cropper.css";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Form, Image, Modal } from "react-bootstrap";
import { useNotify } from "../../hooks/useNotify";
import { API, Utils } from "../../../../../components/Utilities";
import Cropper from "react-cropper";
const PhotoCropper = ({ src, alt = "", onCrop }) => {
    return (
        <Cropper
            src="https://raw.githubusercontent.com/roadmanfong/react-cropper/master/example/img/child.jpg"
            // Cropper.js options
            initialAspectRatio={1}
            aspectRatio={1}
            modal={false}
            viewMode={2}
            background={true}
            // autoCrop={false}
            cropBoxMovable={false}
            cropBoxResizable={false}
            // toggleDragModeOnDblclick={false}
            // guides={false}
            dragMode="move"
            cropend={(elm) => {
                const cropper = elm.target.cropper;
                let url = cropper.getCroppedCanvas().toDataURL();
                console.log(url);
                // onCrop(url)
            }}
        />
    );
};
export const ProfileUpdate = ({ profile }) => {
    const { notify } = useNotify();
    const navigate = useNavigate();
    const [deletionModal, setDeletionModal] = useState(false);
    const [photoModal, setPhotoModal] = useState(false);
    const updateProfile = (e) => {
        e.preventDefault();
        let params = Object.fromEntries(new FormData(e.target).entries())
        console.log(params);
        API.post('account/profile/update', params).then(resp => {
            console.log(resp);
            if (resp && resp.status === true) {
                notify("Change updated");
                setTimeout(() => {
                    navigate('/user/profile');
                }, 1000);

            } else {
                Utils.toast({
                    type: 'error',
                    title: 'Profile update',
                    text: resp && resp.message ? resp.message : 'Error updating profile'
                })
            }
        })

    };
    const deleteAccount = e => {
        console.log('delete user account')
        notify("Account deleted");
        setDeletionModal(false)
    }
    const changeProfilePhoto = e => {
        console.log('Change avatar');
    }
    return (
        <>
            <Form
                method="POST"
                action="#"
                onSubmit={updateProfile}>
                <Form.Group className="form-group d-flex mb-4 align-items-center">
                    <Image
                        src={profile.avatar}
                        className="mr-4 max-w-100px rounded-circle img-thumbnail"
                    />
                    <div className="d-flex">
                        <Button
                            size="sm"
                            className="mr-4 rounded-lg"
                            onClick={(e) => {
                                setPhotoModal(!photoModal);
                            }}>
                            Change Photo
                        </Button>
                        <Button
                            variant="outline-default"
                            size="sm"
                            className="rounded-lg">
                            Remove
                        </Button>
                    </div>
                </Form.Group>
                <Form.Group className="form-group">
                    <Form.Label>
                        First Name
                        <Form.Control
                            type="text"
                            name="first_name"
                            defaultValue={profile?.first_name}
                        />
                    </Form.Label>
                    <Form.Label>
                        Surname
                        <Form.Control
                            type="text"
                            name="surname"
                            defaultValue={profile?.surname}
                        />
                    </Form.Label>
                </Form.Group>
                <Form.Group className="form-group">
                    <Form.Label>
                        Email
                        <Form.Control
                            type="email"
                            name="email_address"
                            defaultValue={profile?.email_address}
                            disabled
                        />
                    </Form.Label>
                </Form.Group>
                <fieldset
                    className="form-group pt-2 pb-4 px-0 my-4 border-top border-bottom"
                    style={{
                        borderStyle: 'solid',
                        borderColor: "#dee2e699",
                        borderWidth: '1px 0 1px 0'
                    }}>
                    <legend className="w-auto px-3 mx-3 font-size-h6">Location</legend>
                    <div className="form-row form-group">
                        <Form.Label as={Col}>
                            Country
                            <Form.Control
                                name="country"
                                defaultValue={profile?.country} />
                        </Form.Label>
                        <Form.Label as={Col}>
                            Province
                            <Form.Control name="province"
                                defaultValue={profile?.province} />
                        </Form.Label>
                        <Form.Label as={Col}>
                            City
                            <Form.Control name="city"
                                defaultValue={profile?.city} />
                        </Form.Label>
                    </div>
                    <Form.Group>
                        <Form.Label>
                            Address Line 1
                            <Form.Control
                                type="text"
                                name="address_line_1"
                                defaultValue={profile?.address_line_1}
                            />
                        </Form.Label>
                        <Form.Label>
                            Address Line 2
                            <Form.Control
                                type="text"
                                name="address_line_2"
                                defaultValue={profile?.address_line_2}
                            />
                        </Form.Label>
                    </Form.Group>
                </fieldset>

                <Form.Group className="form-group mb-4">
                    <Form.Label>
                        Bio
                        <Form.Control
                            as="textarea"
                            name="bio"
                            rows="4"
                            defaultValue={profile?.bio}
                        />
                    </Form.Label>
                </Form.Group>
                <Button
                    type="submit"
                    className="btn-submit btn-block">
                    Edit
                </Button>
                <Button
                    variant="none"
                    className="text-danger btn-block border-0 btn-submit"
                    onClick={(e) => {
                        setDeletionModal(!deletionModal);
                    }}>
                    Delete My Account
                </Button>
            </Form>
            <Modal show={deletionModal} onHide={e => setDeletionModal(false)} centered backdropClassName='bg-dark'>
                <Modal.Header>
                    <Modal.Title>Delete Account</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Your account and associated post is going to be deleted, this action can not be undone</p>
                </Modal.Body>
                <Modal.Footer className='bg-light justify-content-end'>
                    <Button variant="none" className='mr-3' style={{
                        textDecoration: 'underline'
                    }} onClick={e => setDeletionModal(false)}>
                        No Thanks
                    </Button>
                    <Button variant="light" className='bg-white border-0 text-danger px-3' onClick={deleteAccount}>
                        <em className='bx bxs-trash mr-2'></em>
                        Delete My Account
                    </Button>
                </Modal.Footer>

            </Modal>
            <Modal show={photoModal} onHide={e => setPhotoModal(false)} centered size='sm' backdropClassName='bg-dark'>
                <Modal.Body className='position-relative'>
                    <div className='border rounded bg-light p-4 d-flex'>
                        <div className='line-height-lg d-flex m-auto flex-column align-items-center d-flex'>
                            <em className='bx bx-image display-2 mb-2'></em>
                            <span>Drag and drop image files</span>
                            <small className='my-2'>or</small>
                            <Button as='label' variant='dark' size='sm' className='rounded-pill px-4'>
                                <span>Select Image</span>
                                <input type='file' name='' className='d-none' />
                            </Button>
                        </div>
                    </div>
                    <div className='position-absolute outset d-flex zindex-3'>
                        <PhotoCropper src={'/assets/img/logo-old.png'} alt='Profile photo' />
                    </div>
                </Modal.Body>
                <Modal.Footer className='bg-light justify-content-around'>
                    <Button variant="none" className='mr-3 px-4' onClick={e => setPhotoModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" className='border-0 px-3' onClick={changeProfilePhoto}>
                        <em className='bx bx-upload mr-2'></em>
                        Upload Photo
                    </Button>
                </Modal.Footer>

            </Modal>
        </>
    );
};
