import axios from 'axios';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
const _api_base = process.env.NODE_ENV === 'production' ? 'https://sparkuz.000webhostapp.com/' : 'http://localhost/sparkuz/api/';
// axios.defaults.baseURL = _api_base;
export const API = function () {
    const access_token = localStorage.getItem('user_access_token');
    console.log(access_token);
    if (access_token) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${access_token}`
    } else {
        delete axios.defaults.headers.common["Authorization"];
    }
    axios.defaults.baseURL = _api_base;

    return {
        get: (url, headers = {}) => {
            return axios({
                method: 'get',
                responseType: 'json',
                url: url,
                // baseURL: _api_base,
                headers: {
                    Accept: "application/json",
                    ...headers
                },
                timeout: 5000
            }).then(res => {
                if (res.status === 200) {
                    return res.data
                } else {
                    console.log(res);
                }
            }).catch(err => {
                console.log(err);
            });
        },
        post: (url, params = {}, headers = {}) => {
            return axios({
                method: 'post',
                responseType: 'json',
                url: url,
                data: params,
                // baseURL: _api_base,
                headers: { Accept: "application/json", ...headers },
                timeout: 5000
            }).then(res => {
                if (res.status === 200) {
                    return res.data
                } else {
                    console.log(res);
                }
            }).catch(err => {
                console.log(err);
            });
        },
        raw: () => {
            return axios({
                method: 'post',
                url: '/user/12345',
                data: {
                    firstName: 'Fred',
                    lastName: 'Flintstone'
                }
            });
        }
    }
}();
export const Utils = function () {
    const ToastCloseButton = ({ closeToast, theme_color }) => (
        <Button className={'p-1 border-0 mb-auto'} variant={theme_color} onClick={closeToast}>
            <em className='bx bx-x'></em>
        </Button>
    );
    return {
        toast: opts => {
            let msg = opts.text ? opts.text : 'An error occured',
                type = opts.type && ['error', 'danger', 'info', 'success', 'warning', 'primary'].includes(opts.type) ? opts.type : 'error',
                theme_color = type === 'error' ? 'danger' : type,
                theme_class = `bg-${theme_color} text-${type !== 'info' ? 'light' : 'dark'} text-left min-h-50px shadow`,
                icon = '';
            switch (type) {
                case 'success':
                    icon = 'check-circle';
                    break
                case 'info':
                    icon = 'info-circle';
                    break
                case 'warning':
                    icon = 'error';
                    break
                case 'primary':
                    icon = 'bell';
                    break
                default:
                    icon = 'x-circle';
                    break
            }
            toast(<div className='d-flex align-items-center'>
                <em style={{ fontSize: '2.75em' }} className={'bx bx-' + (opts.icon ? icon : icon)}></em>
                <div className='ml-2'>
                    {(opts.title ? <span className='font-size-lg font-weight-bold d-block m-0'>{opts.title}</span> : '')}
                    <span className='font-size-sm'>{msg}</span>
                </div>
            </div>, {
                closeButton: <ToastCloseButton theme_color={theme_color} />,
                type: type,
                icon: false,
                position: "bottom-center",
                autoClose: opts.delay ? opts.delay : true,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: (type === 'dark' || type === 'light') ? type : "colored",
                className: theme_class,
                bodyClassName: 'p-1'
            })
        },
        validate_password: pwd => {
            let value = pwd,
                err_msg = null;
            if (value.length === 0) {
                err_msg = 'Password cannot be empty';
            } else if (value.length < 8) {
                err_msg = "Password should be at least 8 character long";
            } else if (value.search(/[A-Z]/) < 0) {
                err_msg = "Password should have at least one Uppercase character";
            } else if (value.search(/[a-z]/) < 0) {
                err_msg = "Password should have at least one Lowercase character";
            } else if (value.search(/[0-9]/) < 0) {
                err_msg = "Your password must contain at least one digit.";
            }
            return err_msg === null ? true : err_msg
        }
    }
}()