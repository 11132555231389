import React from 'react';
import ReactDOM from 'react-dom/client';
//import './assets/css/theme.css'
import { GoogleOAuthProvider } from '@react-oauth/google';
import 'boxicons/css/boxicons.min.css';
import { HelmetProvider } from 'react-helmet-async';
import { ToastContainer } from 'react-toastify';
import './assets/css/bootstrap.css';
import './assets/css/utilities.css';
import './assets/css/index.css';

import { AppRoutes } from './AppRoutes';
import { UserContext } from './contexts/UserContext';

const helmetContext = {};

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
    <HelmetProvider context={helmetContext}>
      <UserContext>
        <AppRoutes />
        <ToastContainer />
      </UserContext >
    </HelmetProvider>

  </GoogleOAuthProvider>
);